import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';

import { DEFAULT_DATE_FORMAT } from './date-time.const';
import { FormatDateProps } from './date-time.types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = ({
  value,
  format = DEFAULT_DATE_FORMAT,
  isUtc = false,
}: FormatDateProps) => {
  if (!value) return 'N/A';

  const baseDate = isUtc ? dayjs.utc(value).tz(getBrowserTimezone()) : dayjs(value);
  return baseDate.format(format);
};

export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const isPastDate = (date?: string | Date) => {
  if (!date) return false;
  return dayjs().isAfter(dayjs(date));
};
