import { FeatureMap } from "@xemplo/feature-flag";
export enum Features {
  PayrunV2 = "PayrunV2",
  PayrunListV2 = "PayrunListV2",
  DashboardV2 = "DashboardV2",
  AmendmentsV2 = "AmendmentsV2",
  UsersV2 = "UsersV2",
}

export const featureMap: FeatureMap = {
  [Features.PayrunV2]: {
    name: "Payrun V2",
    enabled: true,
  },
  [Features.PayrunListV2]: {
    name: "Payrun List V2",
    enabled: true,
  },
  [Features.DashboardV2]: {
    name: "Dashboard V2",
    enabled: true,
  },
  [Features.AmendmentsV2]: {
    name: "Amendments V2",
    enabled: true,
  },
  [Features.UsersV2]: {
    name: "Users V2",
    enabled: true,
  },
};
