import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import HandleProgressBar from "../Common/progressBar";
import { LazyNavbar } from "../Organization/components/common/Header";
import { AppSidebar } from "../User/components";

export const V2FeatureTemplate = () => {
  return (
    <Fragment>
      <HandleProgressBar />
      <div className="h-screen bg-white dark:bg-darkSecondary overflow-hidden">
        <LazyNavbar />
        <div className="flex h-full">
          <AppSidebar />
          <div className="w-full overflow-y-auto">
            <main
              id="body-wrapper"
              className="overflow-y-auto bg-gray-100 flex-1 content-height"
            >
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
