import { lazy, Suspense } from 'react';

import * as S from './no-result.styles';
import { NoResultProps } from './no-result.types';
import { NoResultsTestId } from './test';

const NoResultImage = lazy(() => import('./no-result-image'));

export const NoResult = ({ title, message = 'No data found' }: NoResultProps) => {
  return (
    <S.StyledNoResultsContainer data-testid={NoResultsTestId.container}>
      <Suspense fallback={null}>
        <NoResultImage />
      </Suspense>
      <S.StyledMessageContainer>
        {title && (
          <S.StyledNoResultsTitle data-testid={NoResultsTestId.title}>
            {title}
          </S.StyledNoResultsTitle>
        )}
        <S.StyledNoResultsMessage data-testid={NoResultsTestId.message}>
          {message}
        </S.StyledNoResultsMessage>
      </S.StyledMessageContainer>
    </S.StyledNoResultsContainer>
  );
};
