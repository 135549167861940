export type FormatDateProps = {
  value?: Date | null;
  format?: string;
  isUtc?: boolean;
};

export enum DisplayDateFormat {
  Default = 'DD/MM/YYYY',
  Short = 'D/M/YY',
  Medium = 'DD MMM YYYY',
}
