import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Company, Instance, Navigation, Persona, User } from '@xemplo/types';

import { DS_BASE_URL, DS_URL_PREFIX, DsAPI } from './directory-services.constants';
import {
  BaseRequestProps,
  DSInstanceConfig,
  PagedRequest,
  RequestWithId,
  RequestWithIds,
  RequestWithParams,
} from './directory-services.types';

export class DirectoryServices {
  private _client: AxiosInstance;
  private _baseRequestConfig: AxiosRequestConfig;

  constructor(config: DSInstanceConfig) {
    this._client = config.client;
    this._baseRequestConfig = {
      baseURL: `${config.baseUrl ?? DS_BASE_URL}/${DS_URL_PREFIX}`,
    };
  }

  public getCurrentUser(props?: BaseRequestProps) {
    return this._client.get<User>(DsAPI.currentUser, this.makeConfig(props));
  }

  public getPersonas(props?: RequestWithParams<PagedRequest>) {
    return this._client.get<Persona[]>(DsAPI.personas, this.makeConfig(props));
  }

  public getInstanceById({ id, ...config }: RequestWithId) {
    return this._client.get<Instance>(DsAPI.instanceById(id), this.makeConfig(config));
  }

  public getCompaniesByInstanceId({ id, ...config }: RequestWithId) {
    return this._client.get<Company[]>(
      DsAPI.companiesByInstanceId(id),
      this.makeConfig(config)
    );
  }

  public getInstances(props?: RequestWithParams<PagedRequest>) {
    return this._client.get<Instance[]>(DsAPI.instances, this.makeConfig(props));
  }

  public getCompanyById({ id, ...config }: RequestWithId) {
    return this._client.get<Company>(DsAPI.companyById(id), this.makeConfig(config));
  }

  public getCompanies({ ids, ...config }: RequestWithIds) {
    const promises = ids.map((id) =>
      this.getCompanyById({ id, ...config }).then((res) => res.data)
    );
    return Promise.all(promises);
  }

  public getNavigation(props?: BaseRequestProps) {
    return this._client.get<Navigation>(DsAPI.navigation, this.makeConfig(props));
  }

  private makeConfig = <T>(props?: RequestWithParams<T>) => {
    return { ...this._baseRequestConfig, ...props };
  };
}

export default DirectoryServices;
