import styled from 'styled-components';

import { BodySmallRegular, Colour } from '@xemplo/style-constants';

export const DateField = styled.div`
  ${BodySmallRegular};
  display: flex;
  align-self: center;
  width: 100%;
  color: ${Colour.Gray[800]};
  transition: all 0.2s ease-out;

  &[data-hasvalue='false'] {
    color: ${Colour.Gray[400]};
  }

  &.align-end {
    align-self: flex-end;
    display: flex;
    visibility: visible;
  }

  /** TODO: Need to check if this will have a class name collision in GP */
  &.hidden {
    opacity: 0;
    z-index: 1;
  }

  :nth-child(even) {
    padding: 0 2px;
  }

  :nth-child(odd) {
    &:focus-visible {
      outline: none;
      background-color: ${Colour.Gray[200]};
    }
  }

  &.input-readonly {
    background: ${Colour.Gray[100]};
    color: ${Colour.Gray[500]};
    pointer-events: none;
  }
`;
