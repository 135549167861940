import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PayrollRoutes } from '@xemplo/gp-routes';
import { activeStatusFilter } from '@xemplo/payrun-base-table';
import { useGetPayrunListQuery } from '@xemplo/payrun-query';
import { Widget } from '@xemplo/widget';

import { columns } from './payrun-list-widget.helper';
import * as S from './payrun-list-widget.styles';

export function PayrunListWidget() {
  const { data, isLoading } = useGetPayrunListQuery({
    requestParams: { page: 1, per_page: 8, q: { statusID: activeStatusFilter } },
  });

  const navigate = useNavigate();

  const footerCount = useMemo(() => {
    if (!data?.result) return 0;
    const { total = 0, page_Size = 0 } = data.result;
    if (total <= page_Size) return 0;
    return total - page_Size;
  }, [data?.result]);

  return (
    <Widget title="Pay Run" count={data?.result?.total}>
      <S.PayrunListTable
        minWidth={800}
        data={data?.result?.rows ?? []}
        columns={columns}
        enablePagination={false}
        enableSorting={false}
        enableRowSelection={false}
        pageCount={data?.result?.total_Pages ?? 0}
        totalRowCount={data?.result?.total ?? 0}
        isLoading={isLoading}
        noResultsText="No active Pay runs"
      />
      {footerCount > 0 && (
        <S.Footer onClick={() => navigate(`/${PayrollRoutes.Payrun}`)}>
          View all (+{footerCount})
        </S.Footer>
      )}
    </Widget>
  );
}

export default PayrunListWidget;
