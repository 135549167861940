import { Fragment, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import HandleProgressBar from "../Common/progressBar";
import { If, Loading } from "../Common/ui";
import { useAppSelector } from "../hooks";
import { LazyNavbar } from "../Organization/components/common/Header";
import { NavBar, AppSidebar } from "../User/components";
import { DetailNavbar } from "../User/components";

type Props = {
  index?: string;
  children?: JSX.Element | JSX.Element[];
};

const TemplateWithSidebar = (props: Props) => {
  const { id, businessunitid, payscheduleid } = useParams();
  const { loadProfile } = useAppSelector((state) => state.member);

  const ApplicationNavbar = useMemo(() => {
    const detailView = !!(id || businessunitid || payscheduleid);
    return detailView ? DetailNavbar : NavBar;
  }, [id, businessunitid, payscheduleid]);

  return (
    <Fragment>
      <HandleProgressBar />
      {/* TODO: Remove this div once the global nav and sidebar is integrated and fully functional */}
      <If
        condition={loadProfile}
        then={<Loading />}
        else={
          <div className="h-screen bg-white dark:bg-darkSecondary overflow-hidden">
            <LazyNavbar />
            <div className="flex h-full">
              <AppSidebar />

              <div className="w-full overflow-y-auto">
                <main
                  id="body-wrapper"
                  className={`w-screen sm:w-full flex-1 overflow-y-auto bg-gray-100 p-6 sm:ml-6 lg:ml-0 content-height`}
                >
                  <ApplicationNavbar />
                  <Outlet />
                </main>
              </div>
            </div>
          </div>
        }
      />
    </Fragment>
  );
};

export default TemplateWithSidebar;
